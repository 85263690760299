.pro-car-img{
    width: 100%;
}
.pro-car-div{
    padding: 0 0.6vw;
}
.pro-title-div{
    margin-top: 1vw;
}
.pro-title-left{
    color: #2B2B2B;
    font-family: var(--font);
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 37.2px */
}
.pro-title-right{
    color: #000;
    font-family: var(--font);
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 100;
    line-height: 120%; /* 37.2px */
}

@media (max-width: 768px) {
    .pro-car-img{
        width: 100%;
    }
    .pro-car-div{
        padding: 0 1.6vw;
    }
    .pro-title-div{
        margin-top: 1.5vw;
    }
    .pro-title-left{
        font-size: 3.5vw;
    }
    .pro-title-right{
        font-size: 3.5vw;
    }
  }