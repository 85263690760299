.inputDiv {
    margin-bottom: 1.2vw;
}

.textInput {
    width: 95%;
    background-color: transparent;
    padding: 1.3vw 0px 1.1vw 1.3vw;
    border: 1px solid #2B2B2B;
    border-radius: 0.5vw;

    color: #000;
    text-align: justify;
    font-family: var(--font);
    font-size: 1vw;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 0.048vw;
    text-transform: uppercase;
}

.textareaInput {
    width: 97.5%;
}

.selectInput {
    caret-color: transparent;
    position: relative;
}

.optionsInput {
    position: absolute;
    border-radius: 1.2vw;
    background: #141414;
    width: 95%;
    margin-top: 0.4vw;
    transition: 0.5s;
    z-index: 99;
    overflow: hidden;
}

.optionInput {
    color: #FFF;
    font-family: var(--font);
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    /* 30px */
    text-transform: uppercase;
    margin: 0.4vw 0;
}

.invalid {
    border-color: red;
}

.error {
    color: red;
    font-family: var(--font);
    font-size: 0.6vw;
    margin-top: 0.1vw;
}

@media (max-width: 768px) {
    .inputDiv {
        margin-bottom: 2.8vw;
    }
    
    .textInput {
        width: 80%;
        padding: 3.3vw 0px 3.1vw 3.3vw;
        border: 1px solid #2B2B2B;
        border-radius: 0.5vw;
    
        font-size: 3vw;
        letter-spacing: 0.048vw;
    }
    
    .textareaInput {
        width: 80%;
    }
    
    .selectInput {
        caret-color: transparent;
        position: relative;
    }
    
    .optionsInput {
        position: absolute;
        border-radius: 1.2vw;
        background: #141414;
        width: 82%;
        margin-top: 0.4vw;
        transition: 0.5s;
        z-index: 99;
        overflow: hidden;
    }
    
    .optionInput {
        color: #FFF;
        font-family: var(--font);
        font-size: 3.2vw;
        font-style: normal;
        font-weight: 400;
        /* 30px */
        text-transform: uppercase;
        margin: 0.6vw 0;
    }
    
    .invalid {
        border-color: red;
    }
    
    .error {
        color: red;
        font-family: var(--font);
        font-size: 1.6vw;
        margin-top: 0.6px;
    }
  }