
/* General scrollbar style */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background-color: #FFF8ED; /* Track background color */
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #2b2b2b; /* Thumb background color */
  border-radius: 10px;
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #000; /* Thumb background color on hover */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  position: relative;
  scroll-behavior:auto
}

a{
  text-decoration: none;
}

body{
  background-color: #FFF8ED;
}

.b{
  font-weight: 400;
}

.vw100{
  width: 100vw;
}
.vh100{
  height: 100vh;
}
.w100{
  width: 100%;
}
.h100{
  height: 100%;
}

/* containters */
.container-pdd{
  padding: 0 2.5vw;
}

.container2{
  margin: 0 auto;
  max-width: 90%;
}

.container3{}



/* flex */
.flex{
  display: flex;
}
.flex-align{
  display: flex;
  align-items: center;
}
.space-between{
  justify-content: space-between;
}
.flex-center{
  display: flex;
  justify-content: center;
}
.flex-all-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-left{
  display: flex;
  justify-content: left;
}
.flex-right{
  display: flex;
  justify-content: right;
}

/* created */
.pointer{
  cursor: pointer;
}
.center{
  text-align: center;
}
.pdd-parent{
  padding: 0 1vw;
}

/* hr */
.hr1{
  margin: 15px auto;
}

/* margin */
.mr-center{
  margin: 0 auto;
}
.mr-t-10{
  margin-top: 10px;
}
.mr-after-heading{
  margin-top: 1.5vw; 
  /* 50px */
}
.mr-t-70{
  margin-top: 3.7vw;
}
.sec-mr-t{
  margin-top: 5.4vw;
}
.sec-mr-t2{
  margin-top: 7vw;
}
.grid-mr-t{
  margin-top: 26px;
}
.no-mr-t{
  margin-top: 0!important;
}


/* comming soon */
.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.coming-soon {
  text-align: center;
}

.logo {
  width: 150px;
  height: auto;
}

h1 {
  font-size: 2.5rem;
  margin: 1rem 0;
}

p {
  font-size: 1vw;
  margin: 0.5rem 0;
}

a {
  color: #61dafb;
}

@media (max-width: 768px) {
  .pdd-parent{
    padding: 2vw 3vw;
  }
}