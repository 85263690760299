footer{
    background-color: #141414;
    padding: 3.3vw 0 1.8vw 0;
}
.footer-header{
    font-family: var(--font);
    color: #FFF8ED;
    font-size: 4.8vw;
    font-style: normal;
    font-weight: 400;
    /* line-height: 120%; */
     /* 115.2px */
    text-transform: uppercase;
}
.foot-contact-btn{
    border-radius: 1.3vw;
    border: 1px solid #FFF;
    padding: 1.2vw 0;
    margin: 3vw auto;
    width: 55vw;
    transition: 0.5s;
}
.foot-contact-btn p{
    text-align: center;
    color: #FFF;
    font-family: var(--font);
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
}
.foot-contact-btn:hover{
    background-color: #FFF8ED;
}
.foot-contact-btn:hover > p{
    color: #2b2b2b;
}
.footer-links{
    margin: 3.2vw auto;
    font-family: var(--font);
    color: #fff;
}
.footer-link-group{
    max-width: 20vw;
}
.footer-link{
    margin: 1vw 0;
}
.footer-link a{
    color: #FFF;
    font-family: var(--font);
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 200;
    line-height: 120%; /* 21.6px */
    text-transform: uppercase;
}
.copyright{
    color: #FFF;
    text-align: center;
    font-family: var(--font);
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 200;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    footer{
        background-color: #141414;
        padding: 3.3vw 0 1.8vw 0;
    }
    .footer-header{
        font-family: var(--font);
        color: #FFF8ED;
        font-size: 5.8vw;
    }
    .foot-contact-btn{
        border-radius: 1.3vw;
        border: 1px solid #FFF;
        padding: 1.2vw 0;
        margin: 3vw auto;
        width: 55vw;
        transition: 0.5s;
    }
    .foot-contact-btn p{
        font-size: 2.5vw;
    }
    .foot-contact-btn:hover{
        background-color: #FFF8ED;
    }
    .foot-contact-btn:hover > p{
        color: #2b2b2b;
    }
    .footer-links{
        margin: 3.2vw auto;
        font-family: var(--font);
        color: #fff;
    }
    .footer-link-group{
        max-width: 100vw;
    }
    .footer-link{
        margin: 1vw 0;
    }
    .footer-link a{
        font-size: 3.2vw;
    }
    .copyright{
        font-size: 2.2vw;
    }
  }