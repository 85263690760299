.our-services{

}
.service-left{
    padding-right: 8.5vw;
}
.service-right{
    padding-left: 2.5vw;
}
.service-left-heading{
    color: #2B2B2B;
    font-family: var(--font);
    font-size: 2.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 57.6px */
    letter-spacing: 0.05vw;
    text-transform: uppercase;
}
.service-right-services{
    color: #2B2B2B;
    font-family: var(--font);
    font-size: 1.6vw;
    font-style: normal;
    font-weight: 200;
    line-height: 205%; /* 38.4px */
    text-transform: uppercase;
}
.service-right-services span{
    margin-right: 1.7vw;
}
.service-hr{
    margin: 3.4vw auto;
    border-top: 0.5px solid #000;
}

@media (max-width: 768px) {
    .service-left{
        padding-right: 0vw;
    }
    .service-right{
        padding-left: 1vw;
    }
    .service-left-heading{
        font-size: 4.4vw;
        letter-spacing: 0.05vw;
    }
    .service-right-services{
        font-size: 3.6vw;
    }
    .service-right-services span{
        margin-right: 1.7vw;
    }
    .service-hr{
        margin: 3.4vw auto;
        border-top: 0.5px solid #000;
    }
  }