.relative{
    position: relative;
}
.absolute{
    position: relative;
}
.main-head{
    color: #2B2B2B;
    font-family: var(--font);
    font-size: var(--fs-main);
    font-style: normal;
    font-weight: 100;
    line-height: 120%;
    text-transform: uppercase;
}

.main-para{
    color: #000;
    text-align: justify;
    font-family: Geist;
    font-size: 0.88vw;
    font-style: normal;
    font-weight: 200;
    line-height: 150%; /* 24px */
    letter-spacing: 0.03vw;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .relative{
        position: relative;
    }
    .absolute{
        position: relative;
    }
    .main-head{
        color: #2B2B2B;
        font-family: var(--font);
        font-size: var(--fs-main);
        font-style: normal;
        font-weight: 100;
        line-height: 120%;
        text-transform: uppercase;
    }
    
    .main-para{
        font-size: 2.88vw;
        letter-spacing: 0.03vw;
    }
  }