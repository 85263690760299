.btn-act-div{
    margin-top: 3.45vw;
}
.btn-act-dark{
    border-radius: 1.3vw;
    border: 1px solid #2B2B2B;
    background: #2B2B2B;
    width: 60vw;
    padding: 0.8vw;
    transition: 0.5s;
}
.btn-act-dark:hover{
    background-color: #FFF8ED;
}
.btn-act-dark .title{
    color: #FFF;
    font-family: var(--font);
    font-size: 1.5vw;
    /* 31px */
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 37.2px */
}
.btn-act-dark:hover > .title{
    color: #2B2B2B;
}

@media (max-width: 768px) {
    .btn-act-div{
        margin-top: 3.45vw;
    }
    .btn-act-dark{
        border-radius: 1.3vw;
        background: #2B2B2B;
        width: 40vw;
    }
    .btn-act-dark:hover{
        background-color: #FFF8ED;
    }
    .btn-act-dark .title{
        font-size: 2.5vw;
    }
    .btn-act-dark:hover > .title{
        color: #2B2B2B;
    }
  }