.nrp {
    width: 10vw;
    height: 10vw;
    animation: rotate 10s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .nrp {
        width: 15vw;
        height: 15vw;
        animation: rotate 10s linear infinite;
    }
  }