.main-heading{
    max-width: 52vw;
}
.main-line{
    max-width: 22.2vw;
}

@media (max-width: 768px) {
    .main-heading{
        max-width: 50vw;
    }
    .main-line{
        max-width: 70vw;
    }
  }