.descript{
    color: #2B2B2B;
    font-family: var(--font);
    font-size: 2.9vw;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
}
.des-data{
    color: #2B2B2B;
    text-align: justify;
    font-family: var(--font);
    font-size: 1.55vw;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 0.028vw;
    text-transform: uppercase;
}
@media (max-width: 768px) {
    .descript{
        font-size: 4.9vw;
    }
    .des-data{
        font-size: 2.55vw;
        letter-spacing: 0.028vw;
    }
  }