.sm-name-head{
    color: #2B2B2B;
    text-align: justify;
    font-family: var(--font);
    font-size: 1.6vw;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025vw;
    text-transform: uppercase;
}
.sm-name-p{
    color: #2B2B2B;
    text-align: justify;
    font-family: var(--font);
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 0.05vw;
    text-transform: uppercase;
}
@media (max-width: 768px) {
    .sm-name-head{
        font-size: 3.6vw;
        letter-spacing: 0.025vw;
    }
    .sm-name-p{
        font-size: 3.5vw;
        letter-spacing: 0.05vw;
    }
  }