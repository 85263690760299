.preloader-box{
    width: 100%;
    height: 100vh;
}
.loader-img{
    width: 5vw;
    height: 5vw;
}
.loading-pkg{
    width: 7vw;
    height: 7vw;
    position: relative;
}
.loader-oval{
    position: absolute;
    top: 0;
    left: 0;

}

@media (max-width: 768px) {
    .loader-img{
        width: 15vw;
        height: 15vw;
    }
    .loading-pkg{
        width: 21vw;
        height: 21vw;
    }
}