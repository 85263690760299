.featured-hr{
    margin: 2vw auto;
    border-top: 0.5px solid #000;
}
.featured-container{
    color: #2B2B2B;
    font-family: var(--font);
    font-size: 32px;
    font-style: normal;
    font-weight: 200;
    line-height: 120%; /* 38.4px */
    text-transform: uppercase;
}
.featured-left, .featured-mid{
    margin-right: 2.6vw;
}