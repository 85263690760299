.menu-div{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: -100vh;
    left: 0;
    z-index: 999;
    transition: 0.5s;
}
.menu-bg {
    position: relative;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: rgba(43, 43, 43, 0.8);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.menu-box{
    width: 40vw;
    height: 40vw;
    border-radius: 50%;
    background-color: #383838;
    position: absolute;
    z-index: 10;
}
.menu-bg-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.menu-close{
    position: absolute;
    top: 2vw;
    right: 2vw;
    z-index: 10;
    color: #E6E0D6;
    font-family: var(--font);
    font-size: 1vw;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 28.8px */
    text-transform: uppercase;
}

.menu-opt{
    color: #FFF8ED;
    text-align: center;
    font-family: var(--font);
    font-size: 4.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 115.2px */
    text-transform: uppercase;
    margin: 0.3vw auto;
    transition: filter 400ms ease 0s;
}
.menu-opt:hover{
    filter: blur(0px)
}
.menu-opt-act{
    filter: blur(0px);
}
.menu-opt-deact{
    filter: blur(5px);
}
.menu-opt-deact span{
    opacity: 0;
}
.menu-opt-act span{
    opacity: 1;
}

@media (max-width: 768px) {
    
    .menu-box{
        width: 80vw;
        height: 80vw;
    }

    .menu-close{
        top: 4vw;
        right: 2vw;
        font-size: 3.5vw;
    }
    
    .menu-opt{
        font-size: 7.8vw;
        margin: 2.6vw auto;
    }
  }