@import url('../fonts/geist.css');

.header{
    border-bottom: 1px solid #141414;
    padding: 1.5vw 0;
}

.logo-text{
    font-family: var(--font);
    color: var(--pfont);
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 700;
}

.menu-text{
    color: var(--pfont);
    font-family: var(--font);
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 28.8px */
}

.bg-slider{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100vw;
    background-color: #000000;
    transition: 0.5s;
    z-index: 9;
}

@media (max-width: 768px) {
    .header{
        border-bottom: 1px solid #141414;
        padding: 1.5vw 0;
    }
    
    .logo-text{
        font-size: 3.3vw;
    }
    
    .menu-text{
        font-size: 3.3vw;
    }
  }
  