.drop-div{
    margin-bottom: 3.3vw;
    z-index: 8;
    display: none; 
    /* hiding because i'm not using */
}
.drop-opt-btn{
    border-radius: 1.5vw;
    background: #141414;
    height: 2vw;
    padding: 1vw 1.5vw;
}
.drop-opt-title{
    color: #FFF;
    font-family: var(--font);
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    margin-right: 0.6vw;
}
.drop-opt-icon{
    width: 2vw;
    height: 2vw;
    transition: 0.5s;
}
.drop-opts{
    position: absolute;
    top: 5.5vw;
    z-index: 7;
    background-color: #141414;
    border-radius: 1.5vw;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
}
.drop-opt{
    margin: 1.4vw 0px;
}

@media (max-width: 768px) {
    
  }