
:root {
    /* font */
    --font: "geist";
    --pfont: #2B2B2B;

    /* font size */
    --fs-main: 5vw;

    /* colors */
    --bgColor: #FFF8ED;
  }

  @media (max-width: 768px) {
    :root {
      /* font size */
      --fs-main: 6vw;
    }
  }